import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from '@service/profile.service';

@Component({
  selector: 'svnl-email-warning-dialog',
  templateUrl: './email-warning-dialog.component.html',
  styleUrls: ['./email-warning-dialog.component.scss'],
})
export class EmailWarningDialogComponent {

  email: string;
  constructor(private readonly profileService: ProfileService,
    private dialogRef: MatDialogRef<EmailWarningDialogComponent>){
    this.profileService.getProfile(false).subscribe(profile => {
      this.email = profile.email;
    });
  }

  ok():void{
    this.profileService.verifyEmails({ change: true }).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
    });
  }

  cancel():void {
    this.profileService.verifyEmails({ change: false }).subscribe({
      next: () => {
        this.dialogRef.close(false);
      },
    });
  }
}
