import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VoteDialogComponent } from '@component/vote-dialog/vote-dialog.component';
import { Candidate } from '@model/candidate';
import { Motivation } from '@model/motivation';
import { ModalService } from '@service/modal.service';

@Component({
  selector: 'svnl-candidate-info',
  templateUrl: './candidate-info.component.html',
  styleUrls: ['./candidate-info.component.scss'],
})
export class CandidateInfoComponent implements OnInit {

  candidate:Candidate;
  motivations: Motivation[];

  constructor(
    private readonly dialogService: ModalService,
    private dialogRef: MatDialogRef<CandidateInfoComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data:Candidate) {

    this.candidate = data;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('100%', '100%');
    this.motivations = JSON.parse(this.candidate.motivation);
  }

  vote():void {
    this.dialog.open(VoteDialogComponent).afterClosed().subscribe(dialogResult => {
      if(dialogResult === true) {
        this.dialogRef.close(true);
      }
    });
  }

  cancel():void {
    this.dialogRef.close(false);
  }

}
