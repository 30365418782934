<mat-card class="vote-information">
  <mat-card-header>
    <mat-card-title>
      <h1 mat-dialog-title class="svnl-header">{{candidate.name}}</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="motivation-content">
    <img mat-card-image src="{{candidate.base64Image}}" alt="Candidate image">
    <mat-card-content class="motivation-text-content" *ngFor="let motivation of motivations">
      <h1 class="svnl-text">{{motivation.title}}:</h1>
      <h3 class="svnl-text">{{motivation.body}}</h3>
    </mat-card-content>
  </mat-card-content>
  <mat-card-footer>
    <mat-dialog-actions>
      <button mat-flat-button color="primary" mat-button
              (click)="vote()"> {{'portal.vote.dialog.ok' | translate}}</button>
      <button mat-flat-button color="warn" mat-button
              (click)="cancel()"> {{'portal.vote.dialog.cancel' | translate}}</button>
    </mat-dialog-actions>
  </mat-card-footer>
</mat-card>

