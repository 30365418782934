import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LuxonModule } from 'luxon-angular';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ToastrModule } from 'ngx-toastr';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

let prodOnlyImports = [];

prodOnlyImports = [
  NgxGoogleAnalyticsModule.forRoot(environment.analytics.ga.code),
  NgxGoogleAnalyticsRouterModule,
  GoogleTagManagerModule.forRoot({
    id: environment.analytics.gtm.code,
  })];

const initKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: {
      url: environment.keycloak.url,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.clientId,
    },
    initOptions: {
      onLoad: 'login-required',
    },
  });

export const multiTranslateHttpLoader = (http: HttpClient) => new MultiTranslateHttpLoader(
  http, [
    {prefix: './assets/translate/', suffix: '.json'},
    {prefix: './assets/translate/countries/', suffix: '.json'},
  ],
);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: multiTranslateHttpLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'nl',
    }),
    SharedModule,
    HttpClientModule,
    LuxonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    }),
    ...prodOnlyImports,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl',
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
