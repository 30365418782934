import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteAccountDialogComponent } from '@component/delete-account-dialog/delete-account-dialog.component';
import { EmailWarningDialogComponent } from '@component/email-warning-dialog/email-warning-dialog.component';
import { LocationComponent } from '@component/location/location.component';
import { RemoveAddressDialogComponent } from '@component/remove-address-dialog/remove-address-dialog.component';
import { VoteDialogComponent } from '@component/vote-dialog/vote-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  constructor(
    private readonly dialog: MatDialog,
  ) {
  }

  openRemoveAddressDialog(): MatDialogRef<RemoveAddressDialogComponent, boolean> {
    return this.dialog.open(RemoveAddressDialogComponent);
  }

  openEmailWarningDialog(): MatDialogRef<EmailWarningDialogComponent, boolean> {
    return this.dialog.open(EmailWarningDialogComponent);
  }

  openDeleteAccountDialog(): MatDialogRef<DeleteAccountDialogComponent, boolean> {
    return this.dialog.open(DeleteAccountDialogComponent);
  }

  openVoteDialog(): MatDialogRef<VoteDialogComponent, boolean> {
    return this.dialog.open(VoteDialogComponent);
  }

  openLocationModal(): MatDialogRef<LocationComponent,string>{
    return this.dialog.open(LocationComponent,{ panelClass:'map-container' });
  }

}



