<div [formGroup]="addressForm" class="svnl-address-check">
  <mat-form-field>
    <mat-label>{{'portal.generic.zipcode' | translate}}</mat-label>
    <input [placeholder]="'portal.generic.zipcode' | translate" formControlName="zipCode" matInput>
    <span *ngIf="isLoading" matSuffix>
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    <mat-error *ngIf="zipCodeControl.hasError('required')">
      {{'portal.address.form.error.zipcode.required' | translate}}
    </mat-error>
    <mat-error *ngIf="zipCodeControl.hasError('pattern')">
      {{'portal.address.form.error.zipcode.invalid' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'portal.generic.houseNumber' | translate}}</mat-label>
    <input [placeholder]="'portal.generic.houseNumber' | translate" formControlName="houseNumber" matInput
           type="number">
    <span *ngIf="isLoading" matSuffix>
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    <mat-error *ngIf="houseNumberControl.hasError('required')">
      {{'portal.address.form.error.houseNumber.required' | translate}}
    </mat-error>
    <mat-error *ngIf="houseNumberControl.hasError('pattern')">
      {{'portal.address.form.error.houseNumber.invalid' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="additionSelect.length > 0 || additionControl.value">
    <mat-label>{{'portal.generic.addition' | translate}}</mat-label>
    <mat-select formControlName="addition" *ngIf="additionSelect.length > 0">
      <mat-option *ngFor="let addition of additionSelect" [value]="addition">
        {{addition}}
      </mat-option>
    </mat-select>
    <input [placeholder]="'portal.generic.addition' | translate"  *ngIf="additionControl.value && additionSelect.length === 0"
           formControlName="addition" matInput
           type="text">
    <mat-error *ngIf="additionControl.hasError('required')">
      {{'portal.address.form.error.houseNumber.required' | translate}}
    </mat-error>
  </mat-form-field>

  <div *ngIf="zipIsInvalid" class="svnl-alert svnl-warning svnl-margin-bottom-2-rem">
    {{ zipcheckErrorMessage | translate }}
  </div>

  <mat-form-field>
    <mat-label>{{'portal.generic.street' | translate}}</mat-label>
    <input [placeholder]="'portal.generic.street' | translate" formControlName="street" matInput>
    <div matSuffix class="align-fa-icon-center">
      <i [ngClass]="{'valid' : streetControl.disabled || streetControl.valid, 'invalid' : streetControl.invalid, 'empty' : streetControl.pristine && !streetControl.value}"
         class="fa fa-check-circle"></i>
    </div>
    <mat-error *ngIf="streetControl.hasError('required')">
      {{'portal.address.form.error.street.required' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'portal.generic.city' | translate}}</mat-label>
    <input [placeholder]="'portal.generic.city' | translate" formControlName="city" matInput>
    <div matSuffix class="align-fa-icon-center">
      <i [ngClass]="{'valid' : cityControl.disabled || cityControl.valid, 'invalid' : cityControl.invalid, 'empty' : cityControl.pristine && !cityControl.value}"
         class="fa fa-check-circle"></i>
    </div>
    <mat-error *ngIf="cityControl.hasError('required')">
      {{'portal.address.form.error.houseNumber.required' | translate}}
    </mat-error>
  </mat-form-field>
</div>
