import { Directive } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AddressResult } from '@model/address-result';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { finalize, Subscription } from 'rxjs';

@Directive()
export abstract class BaseCheckComponent {
  isLoading: boolean;
  zipIsInvalid: boolean;
  zipcheckErrorMessage: string;

  addressForm: FormGroup;
  streetControl: AbstractControl;
  cityControl: AbstractControl;
  zipCodeControl: AbstractControl;
  houseNumberControl: AbstractControl;
  additionControl: AbstractControl;
  countryControl: AbstractControl;

  streetSelect: AddressResult[] = [];
  multipleStreets = false;

  citySelect: AddressResult[] = [];
  multipleCities = false;

  houseNumberSelect: AddressResult[] = [];
  multipleHouseNumbers = false;

  additionSelect: string[] = [];
  houseNumberValueChangeSubscription: Subscription;
  zipcodeValueChangeSubscription: Subscription;
  countryValueChangeSubscription: Subscription;
  streetValueChangeSubscription: Subscription;
  additionValueChangeSubscription: Subscription;

  protected constructor(
    protected readonly formGroupDirective: FormGroupDirective,
    protected readonly addressService: AddressService,
  ) {
    this.addressForm = this.formGroupDirective.control;

    this.streetControl = this.addressForm.get('street');
    this.streetControl.setValidators(Validators.minLength(2));

    this.cityControl = this.addressForm.get('city');
    this.zipCodeControl = this.addressForm.get('zipCode');
    this.zipCodeControl.setValidators(Validators.required);

    this.houseNumberControl = this.addressForm.get('houseNumber');
    this.additionControl = this.addressForm.get('addition');
    this.countryControl = this.addressForm.get('country');

    this.countryValueChangeSubscription = this.countryControl.valueChanges
      .subscribe(() => {

        // Reset address fields
        this.streetControl.setValue('');
        this.cityControl.setValue('');
        this.houseNumberControl.setValue('');
        this.zipCodeControl.setValue('');
        this.additionControl.setValue('');

        this.streetControl.disable();
        this.cityControl.disable();

        this.zipCodeControl.setValidators(
          Validators.pattern(this.addressService.getZipPattern(this.countryControl.value),
          ));
        this.zipCodeControl.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this.zipcodeValueChangeSubscription?.unsubscribe();
    this.houseNumberValueChangeSubscription?.unsubscribe();
    this.streetValueChangeSubscription?.unsubscribe();
    this.additionValueChangeSubscription?.unsubscribe();
  }

  protected findByZipCode(): void {
    this.isLoading = true;

    const zipCheck: ZipCheck = {
      zipCode: this.zipCodeControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: addressCheck => {
          if (addressCheck.resultCount === 0) {
            this.zipIsInvalid = true;
            this.zipcheckErrorMessage = 'portal.address.form.zipcodeWarn';
          } else {
            this.zipIsInvalid = false;
            delete this.zipcheckErrorMessage;

            this.citySelect = this.filterCities(addressCheck.searchResultAddressList);
            this.multipleCities = this.citySelect.length > 1;

            if (this.citySelect.length > 0) {
              this.cityControl.setValue(this.citySelect[0].city);
              if (!this.multipleCities) {
                this.cityControl.disable();
              } else {
                this.cityControl.enable();
              }
            }

            this.streetSelect = this.filterStreet(addressCheck.searchResultAddressList);
            this.multipleStreets = this.streetSelect.length > 1;

            if (this.streetSelect.length > 0) {
              this.streetControl.setValue(this.streetSelect[0].street);
              if (!this.multipleStreets) {
                this.streetControl.disable();
              } else {
                this.streetControl.enable();
              }
            } else {
              this.zipCodeControl.setErrors({ invalid: true });
            }
          }
        },
        error: err => {
          console.warn('[AddressCheck] error while checking address', err);
          this.zipIsInvalid = true;
          this.zipcheckErrorMessage = err.message;
        },
      });
  }

  protected filterStreet(addressList: AddressResult[]): AddressResult[] {
    return addressList.filter((elem, index) =>
      addressList.findIndex(obj => obj.street === elem.street) === index)
      .sort((a, b) => a.street.localeCompare(b.street));
  }

  protected filterCities(addressList: AddressResult[]): AddressResult[] {
    return addressList.filter((elem, index) =>
      addressList.findIndex(obj => obj.city === elem.city) === index)
      .sort((a, b) => a.city.localeCompare(b.city));
  }

  protected filterHousenumber(addressList: AddressResult[], street: string): AddressResult[] {
    return addressList.filter(elem => elem.street === street)
      .sort((a, b) => a.streetNumber - b.streetNumber);
  }

  protected filterAdditions(addressResult: AddressResult[]): string[] {
    const result = addressResult.filter(value => value.addition != null && value.addition.length >= 0);
    result.sort((additionA, additionB) => {
      const nameA = additionA.addition.toUpperCase();
      const nameB = additionB.addition.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    return result.map(value => value.addition);
  }
}
