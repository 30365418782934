import { Injectable } from '@angular/core';

import { Permit } from '../../model/permit';
import { Profile } from '../../model/profile';
import { Providers } from '../../model/providers';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private static USER_KEY = 'user';
  private static PERMITS_KEY = 'permits';
  private static IDP_SETTINGS_KEY = 'idpSettings';

  getUser(): Profile {
    const portalPerson = sessionStorage.getItem(StorageService.USER_KEY);

    return portalPerson ? JSON.parse(portalPerson) : null;
  }

  setUser(user: Profile): void {
    sessionStorage.setItem(StorageService.USER_KEY, JSON.stringify(user));
  }

  getPermits(): Permit[] {
    const permits = sessionStorage.getItem(StorageService.PERMITS_KEY);

    return permits ? JSON.parse(permits) : [];
  }

  setPermits(permits: Permit[]): void {
    sessionStorage.setItem(StorageService.PERMITS_KEY, JSON.stringify(permits));
  }

  getIdpSettings(): Providers {
    const federatedIdentity = sessionStorage.getItem(StorageService.IDP_SETTINGS_KEY);

    return federatedIdentity ? JSON.parse(federatedIdentity) : null;
  }

  setIdpSettings(idpSettings: Providers): void {
    sessionStorage.setItem(StorageService.IDP_SETTINGS_KEY, JSON.stringify(idpSettings));
  }

  clearPermits(): void {
    sessionStorage.removeItem(StorageService.PERMITS_KEY);
  }

  clearUser(): void {
    sessionStorage.removeItem(StorageService.USER_KEY);
  }
}
