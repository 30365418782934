import { Injectable } from '@angular/core';
import { CookieSettings } from '@model/cookieSettings';
import { SettingsCookieService } from '@service/settings-cookie.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { environment } from '../../../environments/environment';


interface AnalyticsEvent {
  event: string,
  category?: string,
  label: string,
  pageName?: string

}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  settings?: CookieSettings;

  constructor(
    private readonly gaService: GoogleAnalyticsService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly settingsCookieService: SettingsCookieService,
  ) {
  }

  async determineSettings():Promise<void>{
    this.settings = this.settingsCookieService.getCookieSettings();
  }

  event(event: AnalyticsEvent): void {
    this.determineSettings().then(() =>{
      if(this.settings.tracking) {
        if (environment.analytics.ga.enabled) {
          this.gaService.event(event.event, event.category, event.label);
        }
        if (environment.analytics.gtm.enabled) {
          this.gtmService.pushTag({ event: event.event, category: event.category, label: event.label });
        }
      }
    });

    if(this.settings) {
      this.determineSettings().then(() => {
        if(this.settings.tracking){
          if (environment.analytics.ga.enabled) {
            this.gaService.event(event.event, event.category, event.label);
          }
          if (environment.analytics.gtm.enabled) {
            this.gtmService.pushTag({ event: event.event, category: event.category, label: event.label });
          }
        }
      });
    }
  }

  pageView(event: AnalyticsEvent): void {
    if (this.settingsCookieService.isTrackingEnabled()) {
      if (environment.analytics.gtm.enabled) {
        this.gtmService.pushTag({ event: event.event, pageName: event.label });
      }
    }
  }
}
