import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Candidate } from '@model/candidate';
import { Election } from '@model/election';
import { Vote } from '@model/vote';
import { BaseService } from '@service/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ElectionService extends BaseService {

  constructor(
    httpClient: HttpClient,
  ){
    super(httpClient, 'elections');
  }

  getElectionIds():Observable<number[]>{
    return this.httpClient.get<number[]>(this.endpointUrl);
  }

  getElectionById(id:number): Observable<Election> {
    return this.httpClient.get<Election>(`${this.endpointUrl}/${id}`);
  }

  getCandidates(id:number): Observable<Candidate[]> {

    return this.httpClient.get<Candidate[]>(`${this.endpointUrl}/${id}/candidates`);
  }

  vote(vote:Vote):Observable<void> {
    return this.httpClient.post<void>(`${this.endpointUrl}/vote`, vote);
  }


}


