
<div class="vote-information">
<h1 mat-dialog-title>{{'portal.vote.dialog.title' | translate}}
</h1>
<mat-dialog-content>
  <div >{{'portal.vote.dialog.description' | translate}}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" mat-button (click)="ok()"> {{'portal.vote.dialog.ok' | translate}}</button>
  <button mat-flat-button color="warn" mat-button (click)="cancel()"> {{'portal.vote.dialog.cancel' | translate}}</button>
</mat-dialog-actions>
</div>
