import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@service/modal.service';

@Component({
  selector: 'svnl-vote-dialog',
  templateUrl: './vote-dialog.component.html',
  styleUrls: ['./vote-dialog.component.scss'],
})
export class VoteDialogComponent implements OnInit {

  constructor(
    private readonly dialogService: ModalService,
    private dialogRef: MatDialogRef<VoteDialogComponent>,
  ) { }

  ngOnInit(): void {
    //
  }

  ok():void {
    this.dialogRef.close(true);
  }

  cancel():void {
    this.dialogRef.close(false);
  }

}
