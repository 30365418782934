import { Injectable } from '@angular/core';
import { CookieSettings } from '@model/cookieSettings';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class SettingsCookieService {

  settings: CookieSettings;
  cookieSettingsKey = 'svnl.portal.settings';

  constructor(
    private cookieService: CookieService,
  ) {
  }

  getCookieSettings(): CookieSettings {
    if (this.cookieService.get(this.cookieSettingsKey)) {
      this.settings = JSON.parse(this.cookieService.get(this.cookieSettingsKey));

      return this.settings;
    }

    return null;
  }

  setCookieSettings(settings: CookieSettings): void {
    this.settings = settings;
    this.cookieService.set(this.cookieSettingsKey, JSON.stringify(settings));
  }

  isTrackingEnabled(): boolean {
    const settings = this.getCookieSettings();

    return (settings) && settings.tracking;
  }

}
