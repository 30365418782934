<h1 mat-dialog-title>{{'portal.modal.emails.title' | translate}}</h1>
<mat-dialog-content>
  <div [innerHTML]="'portal.modal.emails.intro' | translate "></div>
  <p>email</p>
  <div [innerHTML]="'portal.modal.emails.body' | translate "></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="ok()">{{'portal.modal.emails.change' | translate}}</button>
  <button mat-flat-button color="primary" (click)="cancel()">{{'portal.modal.emails.nochange' | translate}}</button>
</mat-dialog-actions>
