import { Component, OnInit } from '@angular/core';
import { DialogService } from '@service/dialog.service';
import { SettingsCookieService } from '@service/settings-cookie.service';

@Component({
  selector: 'svnl-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit{

  showCookies: boolean;
  svnlSeenCookieStatement = 'false';

  constructor(
    private settingCookieService: SettingsCookieService,
    private readonly dialogService: DialogService,
  ) {
  }

  ngOnInit():void {
    if (localStorage.getItem('Svnl-seenCookieStatement')) {
      this.svnlSeenCookieStatement = localStorage.getItem('Svnl-seenCookieStatement');
    }

    if(this.svnlSeenCookieStatement === 'false') {
      this.dialogService.openCookieFooter();
      this.svnlSeenCookieStatement = 'true';
      localStorage.setItem('Svnl-seenCookieStatement',(this.svnlSeenCookieStatement));
    }
  }

  showCookieSettings(): void {
    this.dialogService.openCookieFooter();
    this.showCookies = true;
  }
}
