import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from '@service/profile.service';

@Component({
  selector: 'svnl-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
})
export class DeleteAccountDialogComponent {

  email: string;
  constructor(private readonly profileService: ProfileService,
    private dialogRef: MatDialogRef<DeleteAccountDialogComponent>){
  }

  delete():void{
    this.profileService.deleteProfile().subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
    });
  }

  cancel():void {
    this.dialogRef.close(false);
  }
}
