<form (ngSubmit)="save()" [formGroup]="cookiesForm">
 <div class="title-container">
  <h4 translate="portal.cookies.title"></h4>
 </div>
  <p [innerHTML]="'portal.cookies.body' | translate "></p>

  <mat-divider></mat-divider>

  <div class="toggle-container">
    <div class="toggle-group">
      <p translate="portal.cookies.functional.body"></p>
      <mat-slide-toggle class="button-toggle" color="primary" formControlName="settingsFunctional">
        <span translate="portal.generic.on"></span>
      </mat-slide-toggle>
    </div>

    <mat-divider></mat-divider>

    <div class="toggle-group">
      <p translate="portal.cookies.tracking.body"></p>
      <mat-slide-toggle (change)="trackingChanged($event)" color="primary" formControlName='settingsTracking'>
        <p *ngIf="settings.tracking" translate="portal.generic.on"></p>
        <p *ngIf="!settings.tracking" translate="portal.generic.off"></p>
      </mat-slide-toggle>
    </div>
  </div>

  <br>
<div class="save-button-container">
  <button color="primary" mat-flat-button type="submit">
    <b translate="portal.generic.save"></b>
  </button>
</div>
</form>
