import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective, Validators } from '@angular/forms';
import { AddressResult } from '@model/address-result';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { debounceTime, finalize, merge, tap } from 'rxjs';

import { BaseCheckComponent } from './base-check-component';

@Component({
  selector: 'svnl-address-check',
  templateUrl: './address-check.component.html',
  styleUrls: ['./address-check.component.scss'],
})
export class AddressCheckComponent extends BaseCheckComponent implements OnInit {

  constructor(
    formGroupDirective: FormGroupDirective,
    addressService: AddressService,
  ) {
    super(formGroupDirective, addressService);
  }

  ngOnInit(): void {
    this.addValueChangeListener();
  }

  private addValueChangeListener() {
    this.zipcodeValueChangeSubscription = this.zipCodeControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => {
      this.updateAddress();
    });

    this.houseNumberValueChangeSubscription = this.houseNumberControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => {
      this.updateAddress();
    });

    this.additionValueChangeSubscription = this.additionControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => {
      this.updateAddress();
    });
  }

  private updateAddress() {
    this.isLoading = true;

    if (this.zipCodeControl?.valid && this.houseNumberControl?.valid) {
      const zipCheck: ZipCheck = {
        zipCode: this.zipCodeControl.value,
        housenumber: this.houseNumberControl.value,
        country: this.countryControl.value,
      };

      this.addressService.canValidateAddress(zipCheck)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: addressCheck => {
            if (addressCheck.resultCount === 0) {
              this.zipIsInvalid = true;
              this.zipcheckErrorMessage = 'portal.address.form.zipcodeWarn';
              this.addressForm.setErrors({ 'valid': false });
            } else {
              this.zipIsInvalid = false;
              this.zipcheckErrorMessage = null;
              this.additionSelect = [];
              const found = addressCheck.searchResultAddressList.find(value => +value.streetNumber === +zipCheck.housenumber);

              if (found) {
                this.streetControl.setValue(found.street);
                this.cityControl.setValue(found.city);
              } else {
                this.streetControl.setValue(addressCheck.searchResultAddressList[0].street);
                this.cityControl.setValue(addressCheck.searchResultAddressList[0].city);
              }

              this.streetControl.disable();
              this.cityControl.disable();

              if (addressCheck.resultCount > 1) {
                this.additionSelect = this.filterAdditions(addressCheck.searchResultAddressList);
                this.additionControl.addValidators([Validators.min(0)]);
              }

              // Clear addition value if no additions are found
              if (this.additionSelect.length === 0) {
                this.additionControl.setValue(null);
              }
            }
          },
          error: err => {
            console.warn('[AddressCheck] error while checking address', err);
            this.zipIsInvalid = true;
            this.zipcheckErrorMessage = err.message;
          },
        });
    }
  }
}
