import { Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marker as extract } from '@shavenzov/ngx-translate-extract-marker';

class Language {
  code: string;
  label: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  languages: Language[] = [
    { code: 'nl', label: 'portal.language.country.nl', image: '/assets/icons/language/flag-nl.png' },
    { code: 'de', label: 'portal.language.country.de', image: '/assets/icons/language/flag-de.png' },
    { code: 'en', label: 'portal.language.country.en', image: '/assets/icons/language/flag-en.png' },
    { code: 'fr', label: 'portal.language.country.fr', image: '/assets/icons/language/flag-fr.png' },
    { code: 'pl', label: 'portal.language.country.pl', image: '/assets/icons/language/flag-pl.png' }];

  private language = this.languages[0];

  constructor(private translateService: TranslateService) {
    extract('language.country.nl');
    extract('language.country.de');
    extract('language.country.en');
    extract('language.country.fr');
    extract('language.country.pl');

    this.translateService.addLangs(['nl, de, fr, pl']);
  }

  getLanguage(): Language {
    return this.language;
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  setLanguage(code: string): void {
    if (!code || !this.languages.find(lang => code === lang.code)) {
      console.error(`Cannot set language, code ${code} not found`);
    } else {
      this.language = this.languages[code];
      this.translateService.use(code).subscribe();
    }
  }
}
