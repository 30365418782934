import { Component, OnInit } from '@angular/core';
import { ElectionService } from '@service/election.service';
import { MenuService } from '@service/menu.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'svnl-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  electionIds: number[] = [];

  constructor(
    private readonly menuService: MenuService,
    private readonly electionService: ElectionService,
  ) {
  }

  ngOnInit():void {
    this.electionService.getElectionIds().pipe(
      catchError(error => {
        console.error('Error getting electionId[]:', error);

        return of(null);
      })).subscribe(item => this.electionIds = item);
  }

  selectMenuItem(): void {
    this.menuService.toggleMenu();
  }
}
