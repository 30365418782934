<div class="information">
  <h1 mat-dialog-title>{{config.title | translate}}
  </h1>
  <mat-dialog-content>
    <div >{{config.description | translate}}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" mat-button (click)="ok()"> {{'portal.directDebit.confirmationDialog.confirm' | translate}}</button>
    <button mat-flat-button color="warn" mat-button (click)="cancel()"> {{'portal.directDebit.confirmationDialog.cancel' | translate}}</button>
  </mat-dialog-actions>
</div>
