import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AddressCheckComponent } from '@component/address-check/address-check.component';
import { BelgiumAddressCheckComponent } from '@component/address-check/belgium-address-check.component';
import { GermanAddressCheckComponent } from '@component/address-check/german-address-check.component';
import { CandidateInfoComponent } from '@component/candidate-info/candidate-info.component';
import { ConfirmationDialogComponent } from '@component/confirmation-dialog/confirmation-dialog.component';
import { DeleteAccountDialogComponent } from '@component/delete-account-dialog/delete-account-dialog.component';
import { EmailWarningDialogComponent } from '@component/email-warning-dialog/email-warning-dialog.component';
import { LanguageSelectComponent } from '@component/language-select/language-select.component';
import { LocationComponent } from '@component/location/location.component';
import { LogoutComponent } from '@component/logout/logout.component';
import { MenuComponent } from '@component/menu/menu.component';
import { PageFooterComponent } from '@component/page-footer/page-footer.component';
import { PageHeaderComponent } from '@component/page-header/page-header.component';
import { RemoveAddressDialogComponent } from '@component/remove-address-dialog/remove-address-dialog.component';
import { ToolbarComponent } from '@component/toolbar/toolbar.component';
import { VoteDialogComponent } from '@component/vote-dialog/vote-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

import { CookiesComponent } from './cookies/cookies.component';

const matModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatDatepickerModule,
  MatDialogModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatLuxonDateModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatToolbarModule,
];


@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    LogoutComponent,
    PageFooterComponent,
    PageHeaderComponent,
    ToolbarComponent,
    MenuComponent,
    LanguageSelectComponent,
    RemoveAddressDialogComponent,
    EmailWarningDialogComponent,
    DeleteAccountDialogComponent,
    AddressCheckComponent,
    BelgiumAddressCheckComponent,
    GermanAddressCheckComponent,
    VoteDialogComponent,
    CandidateInfoComponent,
    CookiesComponent,
    LocationComponent,
  ],
  exports: [
    ConfirmationDialogComponent,
    MenuComponent,
    LanguageSelectComponent,
    ToolbarComponent,
    RemoveAddressDialogComponent,
    EmailWarningDialogComponent,
    LogoutComponent,
    PageFooterComponent,
    PageHeaderComponent,
    AddressCheckComponent,
    BelgiumAddressCheckComponent,
    GermanAddressCheckComponent,
    VoteDialogComponent,
    CandidateInfoComponent,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    ...matModules,
    LocationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    ...matModules,
    MatBadgeModule,
    MatBottomSheetModule,
  ],
})
export class SharedModule {
}
