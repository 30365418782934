<div [formGroup]="addressForm" class="svnl-address-check">
  <mat-form-field>
    <mat-label>{{'portal.generic.zipcode' | translate}}</mat-label>
    <input [placeholder]="'portal.generic.zipcode' | translate" formControlName="zipCode" matInput>
    <mat-error *ngIf="zipCodeControl.hasError('required')">
      {{'portal.address.form.error.zipcode.required' | translate}}
    </mat-error>
    <mat-error *ngIf="zipCodeControl.hasError('pattern')">
      {{'portal.address.form.error.zipcode.invalid' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'portal.generic.city' | translate}}</mat-label>
    <mat-select formControlName="city">
      <mat-option *ngFor="let select of citySelect" [value]="select.city">
        {{select.city}}
      </mat-option>
    </mat-select>
    <span matSuffix>
      <i [ngClass]="{ valid : cityControl.valid || cityControl.disabled, invalid : cityControl.invalid }"
         class="fa fa-check-circle"></i>
    </span>
    <mat-error *ngIf="cityControl.hasError('required')" translate="portal.profile.form.error.city.required">
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'portal.generic.street' | translate}}</mat-label>
    <input type="text"
           [placeholder]="'portal.generic.street' | translate"
           matInput
           formControlName="street"
           required
           [matAutocomplete]="Street">
    <mat-autocomplete (closed)="streetCompleteClosed()" #Street="matAutocomplete">
      <mat-option *ngFor="let option of streetSelect" [value]="option.street">
        {{option.street}}
      </mat-option>
    </mat-autocomplete>
    <span matSuffix>
        <i [ngClass]="{ valid : streetControl.valid || streetControl.disabled, invalid : streetControl.invalid }"
           class="fa fa-check-circle"></i>
      </span>
    <mat-error *ngIf="streetControl.hasError('required')">
      <span>{{'portal.address.form.error.zipcode.required' | translate}}</span>
    </mat-error>
  </mat-form-field>


  <mat-form-field>
    <mat-label>{{'portal.generic.houseNumber' | translate}}</mat-label>
    <input type="text"
           [placeholder]="'portal.generic.houseNumber' | translate"
           matInput
           formControlName="houseNumber"
           required>
    <mat-error *ngIf="houseNumberControl.hasError('required')"
               translate="portal.address.form.error.houseNumber.required">
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'portal.generic.addition' | translate}}</mat-label>
    <input [placeholder]="'portal.generic.addition' | translate" formControlName="addition" matInput>
  </mat-form-field>
</div>
