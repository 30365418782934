import { Component, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AddressResult } from '@model/address-result';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { debounceTime, filter, finalize, map, merge, switchMap } from 'rxjs';

import { BaseCheckComponent } from './base-check-component';

@Component({
  selector: 'svnl-german-address-check',
  templateUrl: './german-address-check.component.html',
  styleUrls: ['./address-check.component.scss'],
})
export class GermanAddressCheckComponent extends BaseCheckComponent implements OnInit {
  streetSearch = [];

  constructor(
    formGroupDirective: FormGroupDirective,
    addressService: AddressService,
  ) {
    super(formGroupDirective, addressService);
  }

  ngOnInit(): void {
    if (this.cityControl.value) {
      this.findByZipCode();
    }
    this.addAddressChangedListeners();
  }

  ngDestroy(): void {
    this.streetValueChangeSubscription?.unsubscribe();
  }

  streetCompleteClosed(): void {
    if (this.streetSelect.findIndex(address => address.street === this.streetControl.value) < 0) {
      this.streetControl.reset();
      this.streetSelect = [];
    }
  }

  override findByZipCode(): void {
    this.isLoading = true;
    const zipCheck: ZipCheck = {
      zipCode: this.zipCodeControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(
        finalize(() => this.isLoading = false))
      .subscribe({
        next: addressCheck => {
          if (addressCheck.resultCount === 0) {
            this.zipIsInvalid = true;
            this.zipcheckErrorMessage = 'portal.address.form.zipcodeWarn';
          } else {
            this.zipIsInvalid = false;
            delete this.zipcheckErrorMessage;

            this.citySelect = this.filterCities(addressCheck.searchResultAddressList);
            this.multipleCities = this.citySelect.length > 1;

            if (this.citySelect.length > 0) {
              this.cityControl.setValue(this.citySelect[0].city);
            }
            this.streetControl.enable();
          }
        },
        error: err => {
          console.warn('[AddressCheck] error while checking address', err);
          this.zipIsInvalid = true;
          this.zipcheckErrorMessage = err.message;
        },
      });
  }

  addAddressChangedListeners(): void {
    this.houseNumberValueChangeSubscription?.unsubscribe();

    this.isLoading = true;
    this.zipcodeValueChangeSubscription = this.zipCodeControl.valueChanges.pipe(
      filter(value => value.length === 5),
    ).subscribe(() => {
      this.findByZipCode();
    });

    this.streetValueChangeSubscription = this.streetControl.valueChanges
      .pipe(
        filter(value => value?.length >= 2),
        debounceTime(400),
        switchMap(value => {
          const zipCheck: ZipCheck = {
            city: this.cityControl.value,
            zipCode: this.zipCodeControl.value,
            street: value,
            country: this.countryControl.value,
          };

          // If the current value of Street is in the list of available street names,
          // then prevent the call to the address service (it' not necessary)
          if (this.streetSelect.length > 0) {
            const screenValueInSearchResults = this.streetSelect
              .some(({
                street,
              }) => street.toLowerCase().startsWith(this.streetControl.value.toLowerCase()));

            if (screenValueInSearchResults) {
              return this.streetSearch;
            }
          }

          this.streetSelect = [];

          return this.addressService.canValidateAddress(zipCheck);
        }),
        map(response => {
          if (!response) {
            response = [];
          }

          return this.processSearchResult(response);
        }))
      .subscribe(filteredStreetResults => {
        if (filteredStreetResults.length > 0) {
          this.streetSelect = filteredStreetResults;
          this.streetSearch = filteredStreetResults;
        }
      });
  }

  private processSearchResult({ searchResultAddressList = [] }): AddressResult[] {
    return searchResultAddressList
      .filter(({
        zipCode,
        street,
      }) => zipCode === this.zipCodeControl.value && street?.toLowerCase().startsWith(this.streetControl.value?.toLowerCase()))

      //Create a list with unique street names
      .reduce((acc, value) => acc.some(item => item.street === value.street) ? acc : [...acc, value], []);
  }

}
