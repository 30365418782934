import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'svnl-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit {

  languages = [];
  selectedLanguage;
  showLanguageOptions = false;

  constructor(private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
    this.selectedLanguage = this.languageService.getLanguage();
  }

  setLanguage(language : string): void {
    console.log(`set lang ${JSON.stringify(language)}`);
    this.selectedLanguage = language;
    this.languageService.setLanguage(this.selectedLanguage.code);
    this.showLanguageOptions = false;
  }

  toggle(): void {
    this.showLanguageOptions = !this.showLanguageOptions;
  }
}
