
<div>
  <h1 mat-dialog-title>{{'portal.location.title' | translate}}
  </h1>
  <mat-dialog-content style="max-width: 100vw">
    <div id="map" ></div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" mat-button (click)="ok()"> {{'portal.location.confirm' | translate}}</button>
    <button mat-flat-button color="warn" mat-button (click)="cancel()">{{'portal.location.cancel' | translate}}</button>
  </mat-dialog-actions>
</div>
