import { Component, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ZipCheck } from '@model/zip-check';
import { AddressService } from '@service/address.service';
import { debounceTime, finalize } from 'rxjs';

import { BaseCheckComponent } from './base-check-component';

@Component({
  selector: 'svnl-belgium-address-check',
  templateUrl: './belgium-address-check.component.html',
  styleUrls: ['./address-check.component.scss'],
})
export class BelgiumAddressCheckComponent extends BaseCheckComponent implements OnInit {


  constructor(
    formGroupDirective: FormGroupDirective,
    addressService: AddressService,
  ) {
    super(formGroupDirective, addressService);
  }

  ngOnInit(): void {
    if (this.cityControl.value) {
      this.findByZipCode();
    }
    this.addAddressChangedListeners();
  }

  findByCity(): void {
    const zipCheck: ZipCheck = {
      zipCode: this.zipCodeControl.value,
      city: this.cityControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: value => {
          this.streetSelect = this.filterStreet(value.searchResultAddressList);

          this.multipleStreets = this.streetSelect.length > 1;

          if (this.streetSelect.length > 0) {
            this.streetControl.setValue(this.streetSelect[0].street);
          }
        },
      });
  }

  findByStreet(): void {
    const zipCheck: ZipCheck = {
      zipCode: this.zipCodeControl.value,
      street: this.streetControl.value,
      country: this.countryControl.value,
    };

    this.addressService.canValidateAddress(zipCheck)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: value => {
          this.houseNumberSelect = this.filterHousenumber(value.searchResultAddressList, this.streetControl.value);
          this.multipleHouseNumbers = this.houseNumberSelect.length > 1;
          if (this.houseNumberSelect.length > 0) {
            this.houseNumberControl.setValue(this.houseNumberSelect[0].street);
            this.houseNumberControl.setErrors({ invalid: false });
            if (!this.multipleStreets) {
              this.houseNumberControl.disable();
            } else {
              this.houseNumberControl.enable();
            }
          } else {
            this.houseNumberControl.setErrors({ invalid: true });
          }
        },
      });
  }

  addAddressChangedListeners() :void{
    this.isLoading = true;

    this.zipcodeValueChangeSubscription = this.zipCodeControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => {
      this.findByZipCode();
    });
  }
}
