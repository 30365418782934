import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as L from 'leaflet';


@Component({
  selector: 'svnl-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements  AfterViewInit {
  markers: L.Marker[] = []

  private map!: L.Map;
  private newMarkerPosition: L.LatLng | null = null;

  constructor(
    private dialogRef: MatDialogRef<LocationComponent>,
  ) {}

  ngAfterViewInit():void {
    this.initializeMap();
    this.centerMap();
    this.setupClickListener();
  }

  ok(): void {
    if (this.newMarkerPosition) {
      this.dialogRef.close(this.newMarkerPosition.toString());
    } else {
      this.cancel();
    }
  }

  cancel():void {
    this.dialogRef.close(false);
  }

  private initializeMap(): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const baseMapURl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    this.map = L.map('map');
    L.tileLayer(baseMapURl).addTo(this.map);
  }

  private centerMap(): void {
    this.map.setView([52.2112, 5.9699], 7, undefined);
  }

  private setupClickListener(): void {
    this.map.on('click', (event: L.LeafletMouseEvent) => {
      const { lat, lng } = event.latlng;

      if (this.markers.length > 0) {
        this.map.removeLayer(this.markers[0]);
        this.markers.pop();
      }

      const newMarker = L.marker([lat, lng], {
        draggable: true,
      }).addTo(this.map);

      newMarker.on('dragend', () => {
        this.newMarkerPosition = newMarker.getLatLng();
      });

      this.markers.push(newMarker);
      this.newMarkerPosition = newMarker.getLatLng();
    });
  }
}
