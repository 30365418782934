import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@service/analytics.service';
import { MenuService } from '@service/menu.service';
import { filter, map } from 'rxjs';


@Component({
  selector: 'svnl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('gridWrapper') gridWrapper;
  @ViewChild('scrollWrapper') scrollWrapper;

  constructor(
    private readonly menuService: MenuService,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router) {

    router.events.pipe(filter(event => event instanceof NavigationEnd), map((item: NavigationEnd) => {
      this.analyticsService.pageView({ event: 'page', label: item.url });
    })).subscribe(() => {
      setTimeout(() => {
        this.scrollWrapper.nativeElement.scrollTop = 0;
      }, 0);
    });
  }

  showMenu(): boolean {
    return this.menuService.isOpen;
  }
}

