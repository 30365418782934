import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Profile } from '../../../model/profile';
import { MenuService } from '../../services/menu.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'svnl-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {

  profile$: Observable<Profile>;

  constructor(
    private profileService: ProfileService,
    private menuService: MenuService,
  ) {
  }

  ngOnInit(): void {
    this.profile$ = this.profileService.getProfile(false);
  }

  toggleMenu(): void {
    this.menuService.toggleMenu();
  }
}
