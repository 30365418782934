<h1 mat-dialog-title>{{'portal.modal.profile.delete.title' | translate}}</h1>
<mat-dialog-content>
  <div [innerHTML]="'portal.modal.profile.delete.body' | translate "></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="warn" (click)="delete()" class="btn btn-danger">
    <i class="fa fa-trash"></i>
    <span> {{ 'portal.generic.delete' | translate }}</span>
  </button>
  <button mat-flat-button color="primary" (click)="cancel()">{{'portal.generic.cancel' | translate}}</button>
</mat-dialog-actions>
