import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@model/address';
import { AddressResult } from '@model/address-result';
import { AddressSearchResult } from '@model/address-search-result';
import { ZipCheck } from '@model/zip-check';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends BaseService {

  constructor(
    httpClient: HttpClient,
  ) {
    super(httpClient, 'addresses');
  }

  /**
   * Checks if the address can be validated based on zipCode & houseNumber
   * @param zipCheck The address to retrieve, based on zipcode & houseNumber
   * @returns The complete address, containing city and street
   */
  canValidateAddress(zipCheck: ZipCheck): Observable<AddressSearchResult> {
    return this.httpClient.post<AddressSearchResult>(`${this.endpointUrl}/validate`, zipCheck);
  }

  /**
   * Uses old address service, should be phased out in favor of internal address service.
   * @deprecated deprecated in the backend
   */
  doZipCheck(address: Address): Observable<Address> {
    return this.httpClient.post<Address>(`${this.endpointUrl}/zipCheck`, address);
  }

  save(address: Address): Observable<Address> {
    return this.httpClient.post<Address>(`${this.endpointUrl}`, address);
  }

  isValidZipCode(country: string, zipCode: string): boolean {
    return country && zipCode ? this.checkValid(this.getZipPattern(country), zipCode) : false;
  }

  canZipCheck(countryCode: string): boolean {
    return countryCode === 'NL';
  }

  isValidHouseNumber(houseNumber: string): boolean {
    return this.checkValid(/^[0-9]*$/, houseNumber);
  }

  getZipPattern(countryCode: string): RegExp {
    let zipPattern = /(.*)/i;

    switch (countryCode) {
      case 'NL':
        zipPattern = /^[1-9][0-9]{3}\s?[a-z]{2}$/i;
        break;
      case 'DE':
        zipPattern = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/i;
        break;
      case 'BE':
        zipPattern = /^[1-9][0-9]{3}$/;
        break;
    }

    return zipPattern;
  }

  isAddressComplete(address: Address): boolean {
    if (address == null) return false;

    const requiredFields = ['zipCode', 'country', 'street', 'housenumber', 'city'];

    return requiredFields.every(value => address[value] != null && value !== '');
  }

  mapZipcodeHouseNumber(zipcode: string, houseNumber: number, addressList: AddressResult[]): string {
    const address = addressList.filter(obj =>
      obj.zipCode === zipcode &&
      obj.streetNumber === houseNumber);
    if (address.length === 1) {
      return address[0].street;
    } else {
      return null;
    }
  }

  isForeignAddress(address: Address): boolean {
    return address.country !== 'NL' ?? false;
  }

  private checkValid(regex: RegExp, field: string): boolean {
    return field != null && field !== '' && regex.test(field);
  }
}
