import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CookieSettings } from '@model/cookieSettings';
import { SettingsCookieService } from '@service/settings-cookie.service';

@Component({
  selector: 'svnl-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  cookiesForm: FormGroup;
  settings: CookieSettings;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<CookiesComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly settingsCookieService: SettingsCookieService,
  ) {
  }

  ngOnInit(): void {
    this.createForm();

    this.settings = this.settingsCookieService.getCookieSettings();
    if (this.settings === null) {
      this.settings = {};
      this.settings.tracking = true;
      this.settings.functional = true;
      this.settingsCookieService.setCookieSettings(this.settings);
    }
    this.cookiesForm.get('settingsTracking').setValue(this.settings.tracking);
  }

  save(): void {
    this.settingsCookieService.setCookieSettings(this.settings);
    this.bottomSheetRef.dismiss();
  }

  trackingChanged(event: MatSlideToggleChange): void {
    this.settings.tracking = event.checked;
  }

  private createForm(): void {

    this.cookiesForm = this.formBuilder.group({
      settingsFunctional: { value: true, disabled: true },
      settingsTracking: false,
    });
  }
}
