<div class="svnl-header-toolbar">
  <img src="../../../../assets/images/logo.png" alt="Logo van sportvisserij Nederland"/>
  <div id="toolbar-user-logout">
    <div id="profile" *ngIf="(profile$ | async) as profile">
      <div id="username">
        <span>{{profile.firstName}}</span>
        <span>&nbsp;</span>
        <ng-container *ngIf="profile.infix">
          <span>{{profile.infix}}</span>
          <span>&nbsp;</span>
        </ng-container>
        <ng-container *ngIf="profile.lastName">
          <span>{{profile.lastName}}</span>
        </ng-container>
      </div>
      <svnl-logout></svnl-logout>
    </div>

    <!-- Hide on mobile -->
    <!--<svnl-language-select></svnl-language-select>-->

    <!-- Show on mobile -->
    <i class="fa-solid fa-bars fa-xl" id="toggle-menu" (click)="toggleMenu()"></i>
  </div>
</div>
