<div #gridWrapper class="svnl-grid-wrapper">
  <header class="svnl-grid-header">
    <svnl-page-header></svnl-page-header>
  </header>

  <svnl-menu class="svnl-grid-nav" [ngClass]="this.showMenu() ? 'show' : 'hide'"></svnl-menu>

  <div #scrollWrapper class="svnl-grid-scroll-wrapper" [ngClass]="this.showMenu() ? 'hide' : 'show'">
    <div class="svnl-grid-content">
      <router-outlet></router-outlet>
    </div>

    <footer class="svnl-grid-footer">
      <svnl-page-footer></svnl-page-footer>
    </footer>
  </div>

</div>
