import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { catchError, from } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'svnl-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {

  constructor(
    private keycloakService: KeycloakService,
  ) {
  }

  logout(): void {
    from(this.keycloakService.logout(environment.keycloak.initOptions.redirectUri))
      .pipe(catchError(err => {
        console.error(`Failed to logout : ${err}`);

        return err;
      })).subscribe();
  }
}
