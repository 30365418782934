<div class="lang-cont" [ngClass]="showLanguageOptions ? 'show' : 'hide'">
    <div (click)="toggle()">
        <img [src]="selectedLanguage.image">
        <span [translate]="selectedLanguage.code"></span>
    </div>
    <ul>
        <li *ngFor="let language of languages" [ngClass]="language">
            <span class="divider"></span>
            <div class="language" (click)="setLanguage(language)">
                <img [src]="language.image">
                <span [translate]="language.code"></span>
            </div>
        </li>
    </ul>
</div>
