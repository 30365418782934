<div class="svnl-text" [innerHTML]="'portal.footer.conditions' | translate"></div>
<br/>
<p>
  <a (click)="showCookieSettings()" class="svnl-cookie-click" translate="portal.footer.cookies.link"></a>
  <span [innerHTML]="'portal.footer.cookies.text' | translate"></span>
</p>
<br/>
<div class="svnl-text" [innerHTML]="'portal.footer.contact' | translate"></div>


