import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'fishingwaters',
    loadChildren: () => import('./fishingwaters/fishing-waters.module').then(m => m.FishingWatersModule),
  },
  {
    path: 'invite',
    loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule),
  },
  {
    path: 'permits',
    loadChildren: () => import('./permits/permits.module').then(m => m.PermitsModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'coupling',
    loadChildren: () => import('./coupling/coupling.module').then(m => m.CouplingModule),
  },
  {
    path: 'address',
    loadChildren: () => import('./profile/address-edit/address-edit.module').then(m => m.AddressEditModule),
  },
  {
    path:'elections',
    loadChildren: () => import('./elections/elections.module').then(m => m.ElectionsModule),
  },
  {
    path:'misconduct',
    loadChildren: () => import('./misconduct/misconduct.module').then(m => m.MisconductModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorPageModule),
  },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   redirectTo: 'error/page-not-found',
  // },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
